import { template as template_c37b50955ed840c99dc28897643592b7 } from "@ember/template-compiler";
const FKLabel = template_c37b50955ed840c99dc28897643592b7(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
