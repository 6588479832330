import { template as template_a4816f3720fc47d0aa62798b1a31e0ed } from "@ember/template-compiler";
const FKText = template_a4816f3720fc47d0aa62798b1a31e0ed(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
