import { template as template_ffd766cbde954f22b8d17ca6596cd085 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ffd766cbde954f22b8d17ca6596cd085(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
